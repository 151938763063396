import { ApolloError } from '@apollo/client';
import omit from 'lodash/omit';
import toPairs from 'lodash/toPairs';

import { has } from './has';

function processBadUserInputError(extensions: {
  error?: string;
  exception?: { code?: string };
}) {
  if (extensions.error) {
    return extensions.error;
  } else if (extensions?.exception?.code) {
    return extensions.exception.code;
  } else {
    const validationErrors = omit(extensions, [
      'code',
      'exception',
      'query',
      'serviceName',
      'variables',
    ]);
    return toPairs(validationErrors)
      .map((item) => item.join(': '))
      .join('; ');
  }
}

// Server errors are returned in the non_field_errors field nested in the
// Apollo error object.  This utility can be used to extract these error messages.
export function getApolloError(error: ApolloError, defaultMessage: string) {
  if (error.networkError) {
    return defaultMessage;
  }
  if (!error.graphQLErrors || !error.graphQLErrors[0]) {
    return defaultMessage;
  }
  const { extensions = {}, path = [], message } = error.graphQLErrors[0];

  if (extensions.non_field_errors) {
    return extensions.non_field_errors;
  } else if (extensions.code === 'BAD_USER_INPUT') {
    return processBadUserInputError(extensions) || defaultMessage;
  } else if (extensions.code === 'FORBIDDEN') {
    return error.message || message;
  } else if (
    extensions.code === 'INTERNAL_SERVER_ERROR' ||
    extensions.code === 'DOWNSTREAM_SERVICE_ERROR'
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const possibleMessage = extensions.response?.body?.non_field_errors?.[0];
    return possibleMessage || `Internal server error: ${error.message}`;
  } else if (extensions.code === 'GRAPHQL_VALIDATION_FAILED') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const stackTracePart = extensions.exception?.stacktrace?.[0] || '';
    if (stackTracePart && path.length > 0) {
      return `${stackTracePart}. Path: ${path.join('.')}`;
    }
  } else if (extensions.code === 'MESSAGED_EXCEPTION') {
    return error.message || defaultMessage;
  }

  return defaultMessage;
}

export const tryParseJson = (value: string): unknown => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

export function parseAxiosErrorMessage(
  error: unknown,
  fallbackMessage?: string
): string {
  // Check if error has a response
  if (has(error, 'response')) {
    const response = error.response;

    // Check for error or message in response data
    if (has(response, 'data')) {
      const data = response.data;
      if (has(data, 'error') && typeof data.error === 'string') {
        return data.error;
      }

      if (has(data, 'message') && typeof data.message === 'string') {
        return data.message;
      }
    }
  }

  // Check for message directly in error
  if (has(error, 'message') && typeof error.message === 'string') {
    return error.message;
  }

  // Return fallback message if defined, otherwise undefined
  return fallbackMessage || 'Something went wrong';
}
