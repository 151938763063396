import React from 'react';
import { noop } from 'src/types/basic';
import {
  ShowAsyncNotification,
  ShowNotification,
} from 'src/types/notification';

export interface NotificationContextValue {
  showNotification: ShowNotification;
  showAsyncNotification: ShowAsyncNotification;
}

const initialState: NotificationContextValue = {
  showNotification: noop,
  showAsyncNotification: (promise) => promise,
};

export const NotificationContext = React.createContext<NotificationContextValue>(
  initialState
);
